import React from "react";
import PageHeader from "../ui/PageHeader";
import { call, mail, s1, s2, s3, social } from "../../utils/constant";

const ContactBody = () => {
  return (
    <div className="grid grid-cols-1  sm:grid-cols-3 items-center justify-center gap-3 w-[1120px] max-w-full mx-auto px-5 py-10">
      <div className="w-full rounded-[16px] p-5 bg-white flex flex-col items-center justify-center">
        <div className="">
          <img src={mail} alt="" className="w-[150px] h-[150px]" />
        </div>
        <div className="text-center">
          <div className="">Send us Mail</div>
          <a href="maill:myflexgo.com" className="font-bold">
            hello@myflexgo.com
          </a>
        </div>
      </div>

      <div className="w-fullrounded-[16px] p-5 bg-white flex flex-col items-center justify-center">
        <div className="">
          <img src={call} alt="" className="w-[150px] h-[150px]" />
        </div>
        <div className="text-center">
          <div className="">Call us instead</div>
          <a href="tell:+44 7542 406565" className="font-bold">
         
          </a>
        </div>
      </div>

      <div className="w-full rounded-[16px] p-5 bg-white flex flex-col items-center justify-center">
        <div className="">
          <img src={social} alt="" className="w-[150px] h-[150px]" />
        </div>
        <div className="text-center">
          <div className="flex items-center gap-5 mt-3">
            <div className="w-[40px] h-[40px] bg-slate-500 rounded-full flex items-center justify-center p-2">
              <img src={s1} alt="" className="w-full h-full" />
            </div>
            <div className="w-[40px] h-[40px] bg-slate-500 rounded-full flex items-center justify-center p-2">
              <img src={s2} alt="" className="w-full h-full" />
            </div>
            <div className="w-[40px] h-[40px] bg-slate-500 rounded-full flex items-center justify-center p-2">
              <img src={s3} alt="" className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBody;
