import React from "react";
import Layout from "../components/layout/Layout";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WelcomePage from "../pages/WelcomePage";
import AboutUs from "../pages/AboutUs";
import Contact from "../pages/Contact";
import Blog from "../pages/Blog";
import Faq from "../pages/Faq";
import Terms from "../pages/Terms";
import PageNotFound from "../pages/PageNotFound";
import OurBenefits from "../pages/OurBenefits";
import Nfc from "../pages/Nfc";
import Escro from "../pages/Escro";
import PersonalAccount from "../pages/PersonalAccount";
import MerchantAccount from "../pages/MerchantAccount";
import LocalTransaction from "../pages/LocalTransaction";
import InterNationalTransaction from "../pages/InterNationalTransaction";

const Routes = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <WelcomePage />,
        },
        {
          path: "/about",
          element: <AboutUs />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
        {
          path: "/benefits",
          element: <OurBenefits />,
        },
        {
          path: "/nfc",
          element: <Nfc />,
        },
        {
          path: "/escrow",
          element: <Escro />,
        },
        {
          path: "/personal",
          element: <PersonalAccount />,
        },
        {
          path: "/merchant",
          element: <MerchantAccount />,
        },
        {
          path: "/local",
          element: <LocalTransaction />,
        },
        {
          path: "/international",
          element: <InterNationalTransaction />,
        },

        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default Routes;
