import React from "react";
import { logo, s1, s2, s3, whiteLogo } from "../../utils/constant";
import { Link } from "react-router-dom";

const FooterBody = () => {
  return (
    <div className="">
      <div className="grid justify-between sm:grid-cols-2 md:grid-cols-2 py-16 px-5">
        <div className="">
          <div className="">
            <img src={logo} alt="" className="h-[130px]" />
          </div>
          <div className="text-[13px] md:text-[16px] my-2">
            Follow us on social media
          </div>
          <div className="flex items-center gap-5 mt-3">
            <img src={s1} alt="" className="" />
            <img src={s2} alt="" className="" />
            <img src={s3} alt="" className="" />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 ">
          <div className="grid md:grid-cols-2">
            <div className="mt-10 md:mt-0">
              <div className="">
                <div className="text-[18px] md:text-[22px] mb-3 font-semibold">
                  Contact
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light"></div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  hello@myflexgo.com
                </div>
              </div>
              <div className="mt-10">
                <div className="text-[18px] md:text-[22px] mb-3 font-semibold">
                  Address
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  No 15a Aina Eleko St, <br /> Maryland Lagos. <br />
                  Nigeria
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  08:00 AM - 05:00 PM
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2">
            <div className="mt-10 md:mt-0">
              <div className="">
                <div className="text-[18px] md:text-[22px] mb-3 font-semibold">
                  Company
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  <Link to="/about">About Us</Link>
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  <Link to="/ontact">Contact</Link>
                </div>
                <div className="text-[13px] md:text-[15px] mb-2 font-light">
                  <Link to="/blog"> Blog</Link>
                </div>
              </div>
              <div className="mt-10">
                <div className="">
                  <Link
                    tp="faq"
                    className="text-[18px] md:text-[22px] mb-3 font-semibold"
                  >
                    Support
                  </Link>
                </div>
                <div className="">
                  <Link
                    to="faq"
                    className="text-[13px] md:text-[15px] mb-2 font-light"
                  >
                    FAQs
                  </Link>
                </div>
                <div className="">
                  <Link
                    to="/terms"
                    className="text-[13px] md:text-[15px] mb-2 font-light"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div className="">
                  <Link
                    to="/terms"
                    className="text-[13px] md:text-[15px] mb-2 font-light"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBody;
