import React from "react";
import PageHeader from "../components/ui/PageHeader";
import EscrowList from "../components/escrow/EscrowList"; // Component to display merchant account features
import {
  businessGrowth,
  transactionMonitoring,
  support,
  security,
  ia6,
  ia4,
  ia1,
  ia2,
} from "../utils/constant"; // Icons or images

const MerchantAccount = () => {
  const merchantAccountData = [
    {
      title: "Enhanced Security",
      details:
        "Protect your business transactions with multi-layered security protocols.",
      image: ia6,
    },
    {
      title: "Transaction Monitoring",
      details:
        "Monitor all incoming and outgoing payments in real time for your business.",
      image: ia4,
    },
    {
      title: "Business Growth Tools",
      details:
        "Access analytics and insights to help your business grow and make informed decisions.",
      image: ia1,
    },
    {
      title: "Priority Support",
      details:
        "Get 24/7 support to resolve any account issues and assist with transactions.",
      image: ia2,
    },
  ];

  return (
    <div>
      <PageHeader
        title="Merchant Account"
        details="Empower your business with secure and efficient transaction solutions tailored for merchants."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          Benefits of a Merchant Account
        </div>
        <p>
          A merchant account provides tools and support for secure transactions,
          business growth, and transaction monitoring, allowing you to focus on
          expanding your business.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2 px-5 max-w-full w-[1120px] mx-auto gap-5">
          {merchantAccountData.map((item, i) => (
            <EscrowList
              title={item.title}
              details={item.details}
              key={i}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MerchantAccount;
