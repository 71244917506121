import React from "react";
import PageHeader from "../components/ui/PageHeader";
import WelcomeMore from "../components/welcome/WelcomeMore";
import NFCList from "../components/nfc/NFCList";
import { card, fast, local, sc, secure, snfc } from "../utils/constant";

const Nfc = () => {
  const data = [
    {
      title: "Fast Transaction",
      details:
        "NFC allows for quick, tap-and-go payments, reducing the time needed to complete a transaction.",
      image: fast,
    },
    {
      title: "Secure Transaction",
      details:
        "NFC uses encryption and tokenization to keep your transaction safe, minimizing the risk of fraud.",
      image: secure,
    },
    {
      title: "Convenient Payments",
      details:
        "No need to carry physical cash or cards. Payments can be made by simply tapping your phone.",
      image: local,
    },
    {
      title: "Wide Compatibility",
      details:
        "NFC is supported by most modern smartphones, making it a universally accessible payment method.",
      image: card,
    },

    {
      title: "Peer-to-Peer Transfers",
      details:
        "NFC allows users to quickly transfer money between two phones by simply tapping them together.",

      image: sc,
    },
    {
      title: "No Internet Required",
      details:
        "NFC payments can be processed without the need for an internet connection, providing more flexibility.",
      image: snfc,
    },
  ];

  return (
    <div>
      <PageHeader
        title="NFC Tap to Pay"
        details="Make contactless payments instantly using NFC technology for a fast, secure, and convenient experience."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          What is NFC ?
        </div>
        {/* Explanation of NFC */}
        <p>
          Near Field Communication (NFC) is a wireless technology that allows
          two devices (such as smartphones) to communicate when they are placed
          close together, typically within a few centimeters. It is commonly
          used in contactless payment systems like Apple Pay, Google Pay, or for
          peer-to-peer transfers.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2  px-5 max-w-full w-[1120px] mx-auto gap-5">
          {data.map((item, i) => (
            <NFCList
              title={item?.title}
              details={item?.details}
              key={i}
              image={item?.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Nfc;
