import React from "react";
import PageHeader from "../components/ui/PageHeader";
// Assuming you have a component similar to EscrowList
import {
  secure,
  fast,
  lowCost,
  support,
  ia1,
  ia2,
  ia3,
  ia4,
} from "../utils/constant"; // Replace with relevant icons or images
import EscrowList from "../components/escrow/EscrowList";

const LocalTransaction = () => {
  const transactionData = [
    {
      title: "Instant Transfers",
      details:
        "Send and receive funds within seconds with our secure and fast transaction processing.",
      image: ia1,
    },
    {
      title: "Low Transaction Fees",
      details:
        "Enjoy minimal fees on every transaction, making local payments affordable and accessible.",
      image: ia4,
    },
    {
      title: "Enhanced Security",
      details:
        "Our platform uses top-level encryption and secure protocols to protect your transaction details.",
      image: ia3,
    },
    {
      title: "24/7 Support",
      details:
        "Our team is always available to help you with any questions or concerns about your transactions.",
      image: ia2,
    },
  ];

  return (
    <div>
      <PageHeader
        title="Local Transactions"
        details="Experience quick, affordable, and secure transactions with our local transaction services."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          Why Choose Local Transactions?
        </div>
        <p>
          Local transactions provide a seamless way to transfer funds within the
          same region, ensuring low costs and faster transfer speeds. With our
          platform, you can be assured of security, affordability, and 24/7
          assistance.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2 px-5 max-w-full w-[1120px] mx-auto gap-5">
          {transactionData.map((item, i) => (
            <EscrowList
              title={item.title}
              details={item.details}
              key={i}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocalTransaction;
