import React from "react";
import { pageBg } from "../../utils/constant";

const PageHeader = ({ title, details }) => {
  return (
    <div
      className=" h-[100svh] md:h-[50vh] max-w-[100%] w-[100%]  "
      style={{
        backgroundImage: `url(${pageBg})`,
        backgroundSize: "cover", // Ensure the image covers the whole container
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full bg-gradient-to-r from-red-100 to-blue-50 h-full">
        <div className="h-full px-5 flex flex-col justify-center  w-[1120px] max-w-full mx-auto">
          <div className="font-extrabold text-[50px]">{title}</div>
          <div className="text-[18px] text-gray-700">{details}</div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
