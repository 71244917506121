import React from 'react'
import PageHeader from '../components/ui/PageHeader'

const Terms = () => {
  return (
    <div>
      <PageHeader
        title="Terms of Service"
        details="Last updated: 16 August 2024"
      />
      <p>
        At FlexGo, we are committed to safeguarding your privacy. This Privacy
        Policy outlines how we collect, use, disclose, and protect your personal
        information. By using our services, you consent to the practices
        described in this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect information necessary to provide our telecommunications
        services and enhance your experience. This may include, but is not
        limited to:
      </p>
      <ul>
        <li>Personal Identifiers (e.g., name, address, phone number)</li>
        <li>Payment Information (e.g., credit card details)</li>
        <li>Usage Data (e.g., call logs, data usage)</li>
        <li>Device Information (e.g., IMEI, MAC address)</li>
        <li>Location Data (e.g., for service provisioning)</li>
        <li>Customer Support Data (e.g., inquiries, complaints)</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for various purposes, including:</p>
      <ul>
        <li>Providing, maintaining, and improving our services.</li>
        <li>Billing and account management.</li>
        <li>Responding to customer inquiries and support requests.</li>
        <li>Compliance with legal and regulatory obligations.</li>
        <li>Marketing and promotional activities (with your consent).</li>
      </ul>

      <h2>3. Data Security</h2>
      <p>
        We employ industry-standard security measures to protect your data from
        unauthorized access, disclosure, alteration, and destruction.
      </p>

      <h2>4. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at <a href="mailto:support@flexgo.com">support@flexgo.com</a>.
      </p>
    </div>
  );
}

export default Terms