import React from "react";
import PageHeader from "../components/ui/PageHeader";
import EscrowList from "../components/escrow/EscrowList";// Similar to EscrowList, but tailored for international transactions
import { secure, global, lowFees, support, ia5, ia4, ia3, ia2 } from "../utils/constant"; // Replace with relevant icons or images

const InterNationalTransaction = () => {
  const internationalTransactionData = [
    {
      title: "Global Reach",
      details:
        "Send funds across borders to over 100 countries, supporting multiple currencies.",
      image: ia5,
    },
    {
      title: "Competitive Exchange Rates",
      details:
        "Benefit from low exchange rates, making international transfers cost-effective.",
      image: ia4,
    },
    {
      title: "Enhanced Security",
      details:
        "Our platform uses advanced encryption to keep your international transfers safe.",
      image: ia3,
    },
    {
      title: "24/7 Global Support",
      details:
        "Get assistance anytime with our dedicated support team for international transactions.",
      image: ia2,
    },
  ];

  return (
    <div>
      <PageHeader
        title="International Transactions"
        details="Experience seamless and secure international transfers with competitive exchange rates."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          Why Choose Our International Transaction Services?
        </div>
        <p>
          Our international transaction services make cross-border payments
          quick, secure, and affordable. With competitive rates and support in
          multiple countries, you can send funds worldwide with ease.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2 px-5 max-w-full w-[1120px] mx-auto gap-5">
          {internationalTransactionData.map((item, i) => (
            <EscrowList
              title={item.title}
              details={item.details}
              key={i}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InterNationalTransaction;
