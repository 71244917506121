import React from "react";
import PageHeader from "../components/ui/PageHeader";
import BenefitsList from "../components/benefits/BenefitsList";

const OurBenefits = () => {
  const data = [
    {
      title: "Fee-Free First Transfers",
      details:
        "Enjoy your first three transactions completely fee-free! At Flexgo, we believe in giving you a risk-free experience. Try us, and feel the difference!",
    },
    {
      title: "Transaction Insurance",
      details:
        "All your transfers come with automatic protection. Whether it's local or international, Flexgo’s Transaction Insurance guarantees your money is safe from delays or fraud.",
    },
    {
      title: "Flexgo Rewards",
      details:
        "Earn points for every transaction! Redeem them for cashbacks, discounts, or exclusive offers. The more you use Flexgo, the more you gain.",
    },
    {
      title: "Sustainability Pledge",
      details:
        "Join us in reducing our carbon footprint. Flexgo offsets carbon emissions for every transaction, making every transfer eco-friendly.",
    },
    {
      title: "Quick Dispute Resolution Guarantee",
      details:
        "Your peace of mind matters. If things go wrong, we resolve disputes within 48 hours with our money-back guarantee, ensuring swift and fair outcomes.",
    },
  ];
  return (
    <div>
      <PageHeader title="Our Benefits" />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-[1520px] max-w-full px-5 mx-auto py-10">
        {data.map((item, i) => (
          <BenefitsList title={item?.title} details={item?.details} key={i} />
        ))}
      </div>
    </div>
  );
};

export default OurBenefits;
