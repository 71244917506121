import React from "react";
import { checkIcon, flower, history } from "../../utils/constant";

const WelcomeHistory = () => {
  return (
    <div className="w-[1200px] max-w-[100%] md:h-[350px] mx-auto mt-10 px-5">
      <div
        className="w-full h-full bg-primary text-white rounded-[20px] md:flex items-center justify-center px-10 py-10"
        style={{
          backgroundImage: `url(${flower})`,
          backgroundSize: "300px",
          backgroundPositionX: "right",
          backgroundPositionY: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="md:w-1/2">
          <div className="text-[30px] md:text-[45px] font-semibold">
            Simplify Global Transactions with FlexGo.
          </div>
          <div className="md:flex items-center gap-4 mt-3">
            <div className=" flex items-center gap-2">
              <img src={checkIcon} alt="" className="" />
              <div className="">Fast Payment</div>
            </div>
            <div className=" flex items-center gap-2">
              <img src={checkIcon} alt="" className="" />
              <div className="">Secure transactions</div>
            </div>
            <div className=" flex items-center gap-2">
              <img src={checkIcon} alt="" className="" />
              <div className="">Escrow</div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <img src={history} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeHistory;
