import React from "react";
import WelcomHero from "../components/welcome/WelcomHero";
import WelcomWhy from "../components/welcome/WelcomWhy";
import WelcomeMore from "../components/welcome/WelcomeMore";
import WelcomeHistory from "../components/welcome/WelcomeHistory";
import Testimony from "../components/welcome/Testimony";
import WelcomeSteps from "../components/welcome/WelcomeSteps";

const WelcomePage = () => {
  return (
    <div className="w-full h-full">
      <WelcomHero />
      <WelcomWhy />
      {/* <WelcomeMore /> */}
      <WelcomeHistory />
      <Testimony />
      <WelcomeSteps />
    </div>
  );
};

export default WelcomePage;
