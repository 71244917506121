import React from "react";
import { psBG } from "../../utils/constant";

const WelcomeSteps = () => {
  return (
    <div
      className="w-full py-20 bg-white  mt-16 bg "
      style={{
        backgroundImage: `url(${psBG})`,
        backgroundSize: "contain", // Ensure the image covers the whole container
        backgroundPosition: "right top", // Center the image
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className=" px-5">
        <div className="text-center max-w-[705px] mx-auto">
          <div className="text-[30px] font-bold">
            Open an account and enjoy our products
          </div>
          <div className="">
            Here’s how to create your own account and start enjoying our awesome
            products.
          </div>
        </div>
        <div className="mt-10 max-w-[505px] mx-auto relative">
          <div className="border-l border-primary border-dashed h-full absolute left-6 z-10"></div>
          <div className=" z-20">
            <div className="flex items-center mb-4 gap-4">
              <div className="min-w-[50px] min-h-[50px] bg-primary text-white flex items-center justify-center rounded-full">
                1
              </div>
              <div className="">
                Download app on <b>Google Play </b> or <b>Apple Store</b> .
              </div>
            </div>
            <div className="flex items-center mb-4 gap-4">
              <div className="min-w-[50px] min-h-[50px] bg-primary text-white flex items-center justify-center rounded-full">
                2
              </div>
              <div className="">Tap on “Create Account” Button.</div>
            </div>

            <div className="flex items-center mb-4 gap-4">
              <div className="min-w-[50px] min-h-[50px] bg-primary text-white flex items-center justify-center rounded-full">
                3
              </div>
              <div className="">Fill in your personal information.</div>
            </div>

            <div className="flex items-center mb-4 gap-4">
              <div className="min-w-[50px] min-h-[50px] bg-primary text-white flex items-center justify-center rounded-full">
                4
              </div>
              <div className="">Complete your KYC to enjoy more features.</div>
            </div>
            <div className="flex items-center  gap-4">
              <div className="min-w-[50px] min-h-[50px] bg-primary text-white flex items-center justify-center rounded-full">
                5
              </div>
              <div className="">Now you can start enjoying our products.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSteps;
