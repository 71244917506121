import React from "react";

const TestimonyCard = ({ image, name, post, details }) => {
  return (
    <div className="min-w-[288px] bg-slate-200 rounded-[16px] py-7 px-7">
      <div className="flex gap-3">
        <div className="w-[48px] h-[48px] rounded-full overflow-hidden bg-gray-500">
          {image ? (
            <img src={image} alt="" className="w-full h-full object-cover" />
          ) : (
            ""
          )}
        </div>
        <div className="">
          <div className="font-bold">{name}</div>
          <div className="font-light text-[13px] text-slate-600">{post}</div>
        </div>
      </div>
      <div className="border-l-2 pl-4 border-slate-300 mt-3 text-[17px] font-light ml-6 ">
        {details}
      </div>
    </div>
  );
};

export default TestimonyCard;
