import React from "react";
import FooterTop from "../footer/FooterTop";
import FooterBody from "../footer/FooterBody";

const Footer = () => {
const year = new Date().getFullYear();

  return (
    <div className="bg-slate-900 text-white pt-16 pb-5">
      <div className="w-full max-w-[1200px] mx-auto">
        <FooterTop />
        <FooterBody />
      </div>
      <div className="border-t border-slate-800 text-center text-[13px] md:text-[17px] text-gray-500 pt-5 font-light">
        Copyright © {year} FlexGo technologies. All Right Reserved Terms and
        conditions apply.
      </div>
    </div>
  );
};

export default Footer;
