import React from 'react'

const BenefitsList = ({title, details}) => {
  return (
    <div className='border rounded-[10px] px-5 py-7'>
        <div className="font-bold text-[20px]">{title}</div>
        <div className=" text-gray-800 mt-1 font-light">{details}</div>
    </div>
  )
}

export default BenefitsList