import React, { useState } from "react";

const FaqCard = ({ title, ans }) => {
  const [isShown, setIsShow] = useState(false);

  return (
    <div
      className="w-full rounded-[10px] px-3 py-5 bg-white border cursor-pointer transition-all duration-300 ease-in-out"
      onClick={() => setIsShow(!isShown)}
    >
      <div className="flex items-center justify-between">
        <div className="font-bold text-[14px] ">{title}</div>
        <div className="">{isShown ? "-" : "+"}</div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isShown ? "max-h-[100px] opacity-100" : "max-h-0 opacity-0"
        }`}
        style={{ transitionProperty: "max-height, opacity" }}
      >
        <div className="text-[12px] mt-2">{ans}</div>
      </div>
    </div>
  );
};

export default FaqCard;
