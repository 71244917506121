import React from "react";
import { com1, com2, com3, com4 } from "../../utils/constant";

const team = [
  {
    id: 1,
    name: "Muhammed Bakare Adewale",
    post: "CEO",
    whatsApp: "+442595040405",
    image: com2,
  },
  // {
  //   id: 2,
  //   name: "Dr Hakeem Bakare",
  //   post: "Co Finder",
  //   whatsApp: "+442595040405",
  // },
  {
    id: 3,
    name: "Mustapha Oluwanishola.",
    post: "Senior Developer",
    whatsApp: "+442595040405",
    // image: com3,
  },
];
const AboutUsFooter = () => {
  return (
    <div className="px-5 pb-5  ">
      <div className="text-center text-[30px] font-bold py-5">
        Meet Our Team
      </div>
      <div className="sm:flex gap-5 justify-center items-center sm:w-[600px] max-w-full mx-auto">
        {team.map((user, i) => (
          <div className="w-[280px]" key={i}>
            <div className="w-full px-5 py-5 border rounded-[16px]">
              {user?.image ? (
                <img
                  src={user?.image}
                  alt=""
                  className="w-[238px] h-[257px] rounded-[10px] object-cover"
                />
              ) : (
                <div className="w-[238px] h-[257px] rounded-[10px] bg-slate-100"></div>
              )}

              <div className="py-2">
                <div className="text-[20px] font-medium text-black">
                  {user?.name}
                </div>
                <div className="text-xs font-light text-gray-600">
                  {user?.post}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUsFooter;
