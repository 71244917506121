import React from "react";
import WelcomeGrid from "./WelcomeGrid";
import {
  analysis,
  card,
  dashboard,
  escro,
  inter,
  local,
} from "../../utils/constant";
import WelcomeCard from "./WelcomeCard";

const WelcomWhy = () => {
  return (
    <div className="container mx-auto w-[1200px] max-w-[100%] mt-10 md:my-16 px-5">
      <div className=" text-[25px] md:text-[40px] text-center">
        Why Chooose FlexGo?
      </div>
      <div className="text-center text-slate-400">
        This is why you should use FlexGo for your online transactions.
      </div>
      <div className="mt-5 grid  md:grid-cols-2  items-center justify-center  gap-10">
        <WelcomeGrid
          title="Local Transactions"
          details="Manage and perform seamless transactions within your local region, ensuring fast and secure payments with minimal fees."
          image={local}
          link="/local"
        />
        <WelcomeGrid
          title="International Transactions"
          details="Effortlessly send and receive money across borders with competitive exchange rates and secure, fast transfers."
          image={inter}
          link="/international"
        />
      </div>
      <div className="mt-16">
        <WelcomeCard
          link="/nfc"
          title="NFC (Tap to pay)"
          details="Make contactless payments instantly using NFC technology for a fast, secure, and convenient experience."
          image={card}
        />
      </div>
      <div className="mt-5 ">
        <WelcomeCard
          link="/escrow"
          title="Escrow"
          details="Ensure secure transactions by holding funds in escrow until both parties meet the agreed terms, providing added peace of mind."
          image={escro}
          left={false}
        />
      </div>
      <div className="mt-5 grid md:grid-cols-2  items-center justify-center  gap-10">
        <WelcomeGrid
          title="Personal Account"
          details="Manage your personal finances with ease, offering secure access to your funds and a range of features to help you stay in control."
          image={dashboard}
          link="/personal"
        />
        <WelcomeGrid
          title="Merchant Account"
          details="Designed for businesses, the Merchant Account enables you to receive payments, manage transactions, and streamline your financial operations securely."
          image={analysis}
          link="/merchant"
        />
      </div>
    </div>
  );
};

export default WelcomWhy;
