import React from "react";

const AboutUsDetails = () => {
  return (
    <div className="flex flex-col justify-center py-20 px-5 sm:px-20 w-[1120px] max-w-full mx-auto">
      <div className=" text-gray-700 mb-2">Out Mission</div>
      <div className="text-[35px] font-semibold leading-[40px]">
        Solving Financial Problems
      </div>
      <div className="text-[15px] mt-5">
        <p>
          At Flexgo, we are redefining the way businesses and individuals manage
          their transactions, offering a seamless, secure, and innovative
          payment platform. Whether you're making international or local
          transfers, engaging in peer-to-peer transactions, or facilitating
          multiple payments with just a simple link, Flexgo empowers you to
          handle payments with ease and efficiency.
        </p>
        <br />
        <p>
          Our vision is to simplify financial interactions by providing
          cutting-edge solutions that eliminate the need for physical ATMs or
          complex banking processes. Through our NFC-based contactless payment
          system and versatile platform, we’re ensuring that payments can be
          made swiftly, securely, and on the go.{" "}
        </p>
        <br />
        <p>
          Founded with the goal of addressing the unique needs of users in
          Nigeria and beyond, Flexgo is committed to supporting local
          businesses, entrepreneurs, and everyday users by offering tools that
          make transactions easy, whether online or offline. Our solutions are
          designed to integrate effortlessly into your everyday life or business
          operations, allowing you to focus on what matters most.
        </p>{" "}
        <br />
        <p>
          At Flexgo, we believe in innovation, trust, and delivering an
          exceptional user experience. Join us in shaping the future of
          transactions—flexible, fast, and frictionless.
        </p>
      </div>
    </div>
  );
};

export default AboutUsDetails;
