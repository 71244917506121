import React from "react";
import PageHeader from "../components/ui/PageHeader";
import EscrowList from "../components/escrow/EscrowList"; // Component to display personal account features
import { ai7, ai8, ia1, ia2 } from "../utils/constant";
// import { privacy, easeOfUse, support, savings } from "../utils/constant"; // Icons or images

const PersonalAccount = () => {
  const personalAccountData = [
    {
      title: "Privacy Protection",
      details:
        "Your personal information is secured with industry-leading privacy measures.",
      image: ai7,
    },
    {
      title: "User-Friendly Interface",
      details:
        "Easily manage your finances with a simple and intuitive platform.",
      image: ai8,
    },
    {
      title: "Savings Options",
      details: "Access flexible savings plans to grow your funds over time.",
      image: ia1,
    },
    {
      title: "Dedicated Support",
      details:
        "Our support team is ready to assist you with all personal account inquiries.",
      image: ia2,
    },
  ];

  return (
    <div>
      <PageHeader
        title="Personal Account"
        details="Manage your personal finances with ease, security, and peace of mind."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          Benefits of a Personal Account
        </div>
        <p>
          A personal account helps you securely manage your funds, savings, and
          transactions, providing tools for budgeting and more.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2 px-5 max-w-full w-[1120px] mx-auto gap-5">
          {personalAccountData.map((item, i) => (
            <EscrowList
              title={item.title}
              details={item.details}
              key={i}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalAccount;
