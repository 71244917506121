import emailjs from "emailjs-com";

export const sendFeedbackEmail = async (recipientEmail, recipientName) => {
  const templateParams = {
    name: recipientName,
    to_email: recipientEmail,
    subject: "Help Us Make Flexgo Even Better – We Value Your Feedback!",
  };

  try {
    const result = await emailjs.send(
      "service_37esjee", // Replace with your service ID
      "template_zcfepen", // Replace with your template ID
      templateParams, // Parameters to fill the email template
      "_JP9xD9u3jbAbK8m4" // Replace with your user ID
    );
    console.log("Email sent successfully:", result.text);
    return true;
  } catch (error) {
    console.error("Error sending email:", error.text);
    return false;
  }
};
