import React from "react";
import TestimonyCard from "./TestimonyCard";
import { test1, test2 } from "../../utils/constant";

const Testimony = () => {
  return (
    <div className="my-10 md:my-16 w-[1260px] max-w-[100%] mx-auto px-5">
      <div className="text-[25px] md:text-[40px] text-center font-semibold">
        What Our Clients Are Saying
      </div>
      <div className="text-center text-slate-500 mt-2">
        Our clients share their experiences and success stories with FlexGo's
        services.
      </div>
      <div className="mt-6 md:mt-12 w-full relative overflow-hidden">
        <div className="h-full w-[50px] absolute right-[-20px] top-0 bg-slate-50 blur-[10px]"></div>
        <div className="flex gap-5 overflow-x-auto scrollbar-hide cursor-pointer">
          <TestimonyCard
            name="Ola Adewale"
            post="Seller"
            details="Working with FlexGo has been a game-changer for our startup. Their platform streamlined our financial operations, allowing us to focus on growth. Their team is incredibly supportive and responsive."
          />
          <TestimonyCard
            name="Femi Ogunkunle"
            post="Enginer"
            details="FlexGo’s financial tools are exactly what we needed. They've automated processes that used to take days, giving us more time to focus on strategic decisions. I can't recommend them enough."
          />
          <TestimonyCard
            name="Tosin Yusuf"
            post="Freelancer"
            details="FlexGo has made managing my freelance business so much easier. Their platform simplifies invoicing, payments, and budgeting. It’s become an essential part of my daily routine."
          />
          <TestimonyCard
            name="Blessing Anegbde"
            post="Developer"
            details="FlexGo has completely transformed how we manage our cash flow. Their analytics and reporting tools provide insights we didn't even know we needed, helping us make better business decisions."
          />
          <TestimonyCard
            name="Caleb"
            post="Entrepreneur"
            details="As a small business owner, FlexGo has been invaluable. Their platform is user-friendly and helps me keep track of expenses, invoices, and projections. It's the best financial tool I’ve used so far."
          />
        </div>
      </div>
    </div>
  );
};

export default Testimony;
