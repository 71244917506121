import React from "react";
import { apple, play } from "../../utils/constant";

const FooterTop = () => {
  return (
    <div className="flex flex-col gap-1 items-center justify-center px-5">
      <div className="text-[20px] md:text-[35px] ">
        Get the FlexGo Mobile App.
      </div>
      <div className="max-w-[600px] text-[14px] text-center">
        Revolutionize your financial life with flexgo. Experience the ease and
        convenience for yourself - give it a try today!
      </div>
      <div className="mt-5 sm:flex items-center md:justify-center gap-5 w-full">
        <div className="w-full md:w-[200px] h-[60px] rounded-[16px] mb-2 cursor-pointer bg-white flex items-center justify-center gap-6 md:gap-4">
          <img src={apple} alt="" className="w-[30px]" />
          <div className="">
            <div className="text-[13px] font-light text-black">
              Coming soon to
            </div>
            <div className="text-[15px] text-black">App Store</div>
          </div>
        </div>
        <div className="w-full md:w-[200px] h-[60px] rounded-[16px] mb-2 cursor-pointer border border-white flex items-center justify-center gap-6 md:gap-4">
          <img src={play} alt="" className="w-[30px]" />
          <div className="">
            <div className="text-[13px] font-light text-white">
              Coming soon to
            </div>
            <div className="text-[15px] text-white">Google Play</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;
