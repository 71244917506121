import React from "react";
import { useInView } from "../../hook/useInView";
import { Link } from "react-router-dom";

const WelcomeCard = ({ title, details, image, left = true, link }) => {
  const [isInView, cardRef] = useInView(0.5); // Use the custom hook

  return (
    <Link to={link}>
      <div
        ref={cardRef}
        className="w-full max-w-[100%] h-[500px] md:h-[350px] bg-gradient-to-r from-slate-100 to-indigo-100 px-10 pt-10 rounded-[16px] relative overflow-hidden"
      >
        <div className="absolute bottom-[-150px] w-full h-full flex justify-center">
          <div className="bg-orange-500 opacity-25 w-[420px] h-[391px] rounded-full blur-[700px]"></div>
        </div>
        {/* content */}
        <div
          className={`w-full h-full md:flex ${
            left ? "md:flex-row" : "md:flex-row-reverse"
          } md:justify-between items-center relative`}
        >
          <div>
            <div className="text-[20px] md:text-[35px] font-semibold">
              {title}
            </div>
            <div className="text-[17px] w-[400px] max-w-[100%] text-gray-600">
              {details}
            </div>
          </div>
          <div
            className={`absolute bottom-0  md:relative w-full flex justify-center items-center md:block`}
          >
            <div className="w-[387px] h-[300px] ">
              <img
                src={image}
                alt=""
                className=" max-h-[full] object-contain"
                style={{
                  width: isInView ? "100%" : "95%", // Animate the scale when the card is in view
                  transition: "width 0.5s ease-out", // Smooth transition
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WelcomeCard;
