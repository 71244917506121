import React from "react";
import { logo } from "../../utils/constant";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="px-5 mx-auto">
      <div
        className="md:px-[50px] absolute top-0 left-0 w-full"
        style={{ zIndex: 9999999 }}
      >
        <div className="w-full block sm:flex items-center justify-center sm:justify-between ">
          <div className=" flex items-center justify-center sm:block">
            {/* Logo */}
            <img src={logo} alt="Logo" className="h-[85px]" />
          </div>
          <div className="">
            {/* navigations */}
            <div className="">
              <div className="flex text-[12px] md:text-[15px] items-center justify-center gap-5 text-gray-700">
                <Link to="/">Home</Link>{" "}
                <Link to="/benefits">Our Benefits</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/about">About Us</Link>
                <Link to="/blog">Blog </Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
