import React from "react";

const BlogCard = ({ title, details, image, date }) => {
  return (
    <div className="w-full border border-slate-100">
      <img
        src={image}
        alt=""
        className="w-full object-contain rounded-[10px]"
      />
      <div
        className="my-2 px-5 pb-[20px]">
            <div className="text-gray-500 text-[13px]">{date}</div>
            <div className=" font-bold text-[17px] mt-2">{title}</div>
            <div className="text-[13px]">{details}</div>
        </div>
    </div>
  );
};

export default BlogCard;
