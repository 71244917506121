import React from "react";
import PageHeader from "../components/ui/PageHeader";
import EscrowList from "../components/escrow/EscrowList"; // Assuming you have an EscrowList component similar to NFCList
import { secure, escro, low, analysis, link } from "../utils/constant"; // Replace with relevant icons or images

const Escrow = () => {
  const escrowData = [
    {
      title: "Secure Transactions",
      details:
        "Escrow ensures that funds are only released when all transaction conditions are met, providing an additional layer of security.",
      image: secure,
    },
    {
      title: "Transparency in Fees",
      details:
        "Know exactly what you’re paying. Escrow provides a clear and transparent fee structure, free from hidden charges.",
      image: escro,
    },
    {
      title: "Pay with Link",
      details:
        "Easily accept payments by sharing a secure payment link. Customers can pay from anywhere, enhancing convenience and flexibility.",
      image: link,
    },
    {
      title: "24/7 Support and Assistance",
      details:
        "Get assistance whenever you need it. Our team is available around the clock to support your escrow needs.",
      image: analysis,
    },
  ];

  return (
    <div>
      <PageHeader
        title="Escrow Services"
        details="Experience secure, transparent, and insured transactions with our escrow service."
      />
      <div className="mx-auto max-w-full w-[1120px] px-5 py-10 text-[17px] font-light">
        <div className="text-[25px] text-gray-700 font-bold mb-1">
          What is Escrow?
        </div>
        {/* Explanation of Escrow */}
        <p>
          Escrow is a financial arrangement where a third party holds and
          regulates payment of funds required for two parties involved in a
          given transaction. It helps make transactions more secure by keeping
          the payment in a secure account which is only released when all terms
          of the agreement are met.
        </p>

        <div className="grid mt-5 items-center justify-center grid-cols-1 sm:grid-cols-2 px-5 max-w-full w-[1120px] mx-auto gap-5">
          {escrowData.map((item, i) => (
            <EscrowList
              title={item.title}
              details={item.details}
              key={i}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Escrow;
