import React from "react";
import Routes from "./utils/Routes";

const App = () => {
  return (
    <div className=" bg-slate-50">
      <Routes />
    </div>
  );
};

export default App;
