import React from "react";
import { Link } from "react-router-dom";

const WelcomeGrid = ({ title, details, image, link }) => {
  return (
    <Link to={link}>
      <div className="w-full  pl-10 py-10 sm:w-[500px] max-w-full h-[350px] sm:h-[250px] md:h-[300px] rounded-[10px] bg-white relative overflow-hidden">
        <div className="font-bold text-[20px] sm:max-w-[200px]">{title}</div>
        <div className="md:flex justify-between relative">
          <div className="max-w-[300px] text-[14px]">{details}</div>
        </div>
        <div className="absolute flex right-[20%] bottom-[-200px] sm:right-[-50px] ">
          <img src={image} alt="" className="w-[200px]" />
        </div>
      </div>
    </Link>
  );
};

export default WelcomeGrid;
