import React from "react";
import PageHeader from "../components/ui/PageHeader";
import AboutUsDetails from "../components/aboutUs/AboutUsDetails";
import AboutUsFooter from "../components/aboutUs/AboutUsFooter";

const AboutUs = () => {
  return (
    <div className="">
      <PageHeader
        title="About us"
        details="Our team at FlexGo is dedicated to providing innovative financial solutions to help you achieve your goals. We believe in simplifying the complexities of finance to empower our customers to make informed decisions. With a focus on user experience and cutting-edge technology, we strive to deliver a seamless and secure platform for all your financial needs."
      />
      <AboutUsDetails />

      <AboutUsFooter />
    </div>
  );
};

export default AboutUs;
