import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="w-screen h-screen flex sm:items-center justify-center flex-col px-5 bg-gradient-to-r from-red-100 to-blue-50 ">
      <div className="text-[70px] ">404</div>
      <div className="text-gray-700 w-[600px] max-w-full">
        Page not found. This could be the result of page being removed, the name
        being changed or the page being temporarily unavailable.
      </div>
      <Link to="/" className="">
        <div className="text-white px-[10px] py-[5px] bg-primary w-[200px] h-[50px] mt-3 flex items-center justify-center rounded-[8px]">
          Go Back Home
        </div>
      </Link>
    </div>
  );
};

export default PageNotFound;
