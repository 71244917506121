import React from 'react'

const EscrowList = ({ title, details, image }) => {
  return (
    <div className="border-gray-200 border rounded-[10px] py-5 px-5">
      <div className="w-full h-[200px] ">
        {image && <img src={image} className="w-full h-full object-contain" />}
      </div>
      <div className="font-bold text-[18px] mt-2 ">{title}</div>
      <div className="text-gray-500 font-extralight text-[13px]">{details}</div>
    </div>
  );
};

export default EscrowList