import React from "react";
import PageHeader from "../components/ui/PageHeader";
import BlogCard from "../components/blog/BlogCard";
import { blog1, blog2, blog3, blog4 } from "../utils/constant";

const Blog = () => {
  return (
    <div >
      <PageHeader
        title="Blog"
        details="We are excited to hear from you and help you with any question or concerns you may have. "
      />
      <div className="grid grid-cols-1 md:grid-cols-2 w-[1120px] max-w-full px-5 mt-20 gap-5 mx-auto">
        <BlogCard
          title="The Future of Contactless Payments"
          date="08 May 2024"
          details="In the ever-evolving landscape of digital transactions, contactless payments have emerged as a game-changer. With the rise of mobile wallets and NFC technology, the future of contactless payments is brighter than ever. "
          image={blog1}
        />
        <BlogCard
          title="What is Escrow and How Can It Protect Your Transactions?"
          date="12 June 2024"
          details="Escrow services play a crucial role in ensuring secure transactions between parties. When you engage in a transaction with someone you don't know well, escrow can act as a trusted intermediary to hold funds until both parties fulfill their obligations. "
          image={blog2}
        />
        <BlogCard
          title="Personal vs. Merchant Accounts: Which One Do You Need?"
          date="05 July 2024"
          details="Are you trying to decide between a personal account or a merchant account? Let's explore the differences and help you make the right choice. "
          image={blog3}
        />
        <BlogCard
          title="Managing Cross-Border Payments with International Transactions"
          date="20 August 2024"
          details="Are you looking to streamline your cross-border payment processes? In our latest blog post, 'Managing Cross-Border Payments with International Transactions,' we delve into effective strategies for handling international transactions seamlessly."
          image={blog4}
        />
      </div>
    </div>
  );
};

export default Blog;
