import React, { useState } from "react";
import { doodle, phone1, phone2 } from "../../utils/constant";
import { useInView } from "../../hook/useInView";
import { sendFeedbackEmail } from "../../hook/useSendMail";

const WelcomeHero = () => {
  const [isInView, cardRef] = useInView(0.5); // Use the custom hook
  const [email, setEmail] = useState(""); // State for email input
  const [error, setError] = useState(""); // State for error messages
  const [success, setSuccess] = useState(""); // State for success messages
  const [loading, setLoading] = useState("");

  const handleSendEmail = async () => {
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      setLoading(true);
      setError(""); // Clear error if successful
      await sendFeedbackEmail(email); // Send email
      setSuccess("Email added successfully!");

      setEmail(""); // Clear email input
    } catch (error) {
      setError("Failed to add email. Please try again.");
      setSuccess(""); // Clear success message on error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-r from-red-50 to-blue-50 flex items-center justify-center relative overflow-hidden">
      <div
        className="w-screen min-h-screen flex items-center justify-center flex-col"
        style={{
          backgroundImage: `url(${doodle})`,
          backgroundSize: "cover", // Ensure the image covers the whole container
          backgroundPosition: "center", // Center the image
          backgroundPositionX: 400,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full text-center h-full flex items-center flex-1 mt-[200px] md:mt-[140px] flex-col">
          <div className="text-[35px] font-bold sm:text-[60px] md:text-[70px] text-center leading-[50px] sm:leading-[70px] px-5 sm:font-semibold max-w-[1000px]">
            Experience the Future of Payments with FlexGo
          </div>
          <div className="text-[15px] md:text-[20px] mt-4 text-gray-600 w-[900px] max-w-[100%] px-5">
            Experience unmatched convenience with FlexGo - the easy solution for
            all your money transfer needs. Say goodbye to traditional limits and
            embrace a seamless global money transfer experience.
          </div>
          <div className="mt-[30px] max-w-[100%] flex items-center gap-3 px-5">
            <div>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state on change
                className="bg-white rounded-[8px] px-3 py-3 w-[200px] md:w-[350px] flex justify-center"
                placeholder="Enter email address"
              />
            </div>
            <button
              onClick={handleSendEmail} // Call function on button click
              className="text-[15px] min-w-[120px] px-3 py-3 md:w-[162px] bg-primary text-white rounded-[8px]"
            >
              {loading ? "Adding..." : "Join Waitlist"}
            </button>
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}{" "}
          {/* Display error messages */}
          {success && <div className="text-green-500 mt-2">{success}</div>}{" "}
          {/* Display success messages */}
        </div>
        <div
          className="flex mt-10 text-[20px] font-semibold justify-end items-baseline bottom-5"
          ref={cardRef}
        >
          <img
            src={phone1}
            alt=""
            className="h-[326px]"
            style={{
              transform: isInView ? "scale(1)" : "scale(0.70)", // Animate the scale when the card is in view
              transition: "transform 0.5s ease-out", // Smooth transition
            }}
          />
          <img
            src={phone2}
            alt=""
            className="h-[401px]"
            style={{
              transform: isInView ? "scale(1)" : "scale(0.70)", // Animate the scale when the card is in view
              transition: "transform 0.5s ease-out", // Smooth transition
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeHero;
