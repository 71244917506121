import React from 'react'
import PageHeader from '../components/ui/PageHeader';
import ContactBody from '../components/contact/ContactBody';

const Contact = () => {
  return (
    <div>

      <PageHeader 
        title="Contact Us"
        details="We are excited to hear from you and help you with any question or concerns you may have. "
      />
      <ContactBody />
    </div>
  );
}

export default Contact