import React from "react";
import PageHeader from "../components/ui/PageHeader";
import FaqCard from "../components/faq/FaqCard";

const Faq = () => {
const faqData = [
  {
    title: "What is FlexGo?",
    ans: "FlexGo is an innovative online banking app that allows users to manage their finances, make payments, and transfer funds using advanced technologies like Near Field Communication (NFC). With FlexGo, you can easily tap your phone for secure, contactless transactions.",
  },
  {
    title: "How does NFC work in FlexGo?",
    ans: "NFC (Near Field Communication) allows you to make secure, contactless payments by tapping your phone on compatible terminals. FlexGo uses encrypted communication to ensure your payment details are safe during the transaction.",
  },
  {
    title: "How do I reset my password?",
    ans: "To reset your password, go to the login page and click on 'Forgot Password.' Follow the instructions to receive a reset link via email or SMS, where you can create a new password.",
  },
  {
    title: "How do I contact customer support?",
    ans: "You can contact FlexGo customer support through the in-app chat feature, by calling our support line, or by emailing us at support@flexgo.com. We are available 24/7 to assist you with any issues.",
  },
  {
    title: "Can I link multiple bank accounts?",
    ans: "Yes, FlexGo allows you to link multiple bank accounts from different banks. You can manage all your accounts in one place and switch between them for transactions.",
  },
  {
    title: "What security measures are in place?",
    ans: "FlexGo uses advanced security measures like biometric authentication (fingerprint or face ID), encryption, and two-factor authentication (2FA) to protect your account. All transactions are securely processed with end-to-end encryption.",
  },
  {
    title: "How do I create an account?",
    ans: "To create a FlexGo account, download the app from the App Store or Google Play, and follow the signup process. You will need to provide your personal details and verify your identity using your phone number or government-issued ID.",
  },
  {
    title: "What are the fees for transactions?",
    ans: "FlexGo offers free internal transfers between FlexGo accounts. For external transfers, a small fee may apply depending on the destination bank and type of transaction. Please refer to the fees section in the app for more details.",
  },
  {
    title: "How do I verify my identity?",
    ans: "You can verify your identity by submitting your National Identification Number (NIN) or Bank Verification Number (BVN). FlexGo uses secure verification to ensure compliance with banking regulations and protect your account.",
  },
  {
    title: "How do I close my account?",
    ans: "To close your FlexGo account, go to the settings in the app, navigate to 'Account Settings,' and choose 'Close Account.' You may also contact customer support for assistance with closing your account.",
  },
  {
    title: "How do I update my personal information?",
    ans: "To update your personal information, go to the 'Profile' section in the app and edit the necessary details. Make sure to verify your changes with a confirmation code sent to your registered phone or email.",
  },
];

  return (
    <div>
      <PageHeader
        title="Frequently Asked Questions"
        details="We’re here to help with all your questions and answers in one place."
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-[1120px] max-w-full px-5 py-10 mx-auto">
        {faqData.map((details, key) => (
          <div className="">
            <FaqCard title={details?.title} ans={details?.ans} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
